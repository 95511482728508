#scrollTop {
  position: fixed;
  width: 1px;
  left: 5%;
  bottom: 20%;
  height: 0px;
  font-size: 3rem;
  z-index: 10;
  cursor: pointer;
  color: rgb(0, 0, 0, .5);
  background-color: transparent;
  box-shadow: none;
}
